import axios from 'axios'
import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import DeviceCard from './DeviceCard'

dayjs.extend(relativeTime)

function DevicesSettings({ activeTab, isDarkMode }) {
	const [loading, setLoading] = useState(false)
	const [currentDevice, setCurrentDevice] = useState(null)
	const [otherDevices, setOtherDevices] = useState([])

	useEffect(() => {
		const fetcherDevices = async () => {
			try {
				setLoading(true)

				if (activeTab !== 'devices') return

				const token = localStorage.getItem('jwtToken')
				const user = jwtDecode(token)

				const response = await axios.get('/api/user/devices')

				const allDevices = response.data

				const current = allDevices.find(
					device => device.deviceId === user.deviceId
				)
				const others = allDevices.filter(
					device => device.deviceId !== user.deviceId
				)

				setCurrentDevice(current)
				setOtherDevices(others)
				setLoading(false)
			} catch (error) {
				console.log('error', error)
				setLoading(false)
			}
		}
		fetcherDevices()
	}, [activeTab])

	const headingColorClass = isDarkMode ? 'text-white' : 'text-gray-900'

	return (
		<>
			{loading ? (
				<h1
					style={{
						textAlign: 'center',
						fontSize: '24px',
						fontWeight: 'normal',
						color: isDarkMode ? 'white' : 'black',
					}}
				>
					Loading...
				</h1>
			) : (
				<div
					className={`max-w-4xl mx-auto ${
						isDarkMode ? '' : 'bg-gray-100'
					} p-6 rounded-lg`}
				>
					<h2 className={`text-xl font-semibold mb-4 ${headingColorClass}`}>
						Current Device
					</h2>
					{currentDevice && (
						<DeviceCard
							device={currentDevice}
							isCurrent={true}
							setLoading={setLoading}
						/>
					)}

					<h2
						className={`text-xl font-semibold mt-6 mb-4 ${headingColorClass}`}
					>
						Other {otherDevices.length} Devices
					</h2>
					{otherDevices.map(device => (
						<DeviceCard
							key={device._id}
							device={device}
							isCurrent={false}
							setLoading={setLoading}
						/>
					))}
				</div>
			)}
		</>
	)
}

export default DevicesSettings

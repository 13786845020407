import { useState, useEffect } from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import { Bars3Icon, SunIcon, MoonIcon } from '@heroicons/react/20/solid'

import Sidebar from '../Sidebar'
import LoggedOutModal from '../AuthPages/LoggedOutModal'
import { Logo } from '../LandingPage/Logo'

import { useDarkMode } from '../../context/DarkModeContext'

import { baseURL } from '../../config'

import GeneralSettings from './GeneralSettings'
import DevicesSettings from './DevicesSettings'

export default function SettingsMain() {
	const { isDarkMode, toggleDarkMode } = useDarkMode()

	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [invoiceData, setInvoiceData] = useState(null)
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(false)
	const [activeTab, setActiveTab] = useState('general')

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen)
	}

	const handleManageBilling = async () => {
		try {
			if (!user || !user.email) {
				console.error('User email is not available.')
				return
			}

			const response = await axios.get(
				`${baseURL}/api/stripe/customer-portal`,
				{
					params: { email: user.email },
				}
			)

			window.location.href = response.data
		} catch (error) {
			console.error('Error redirecting to Stripe Customer Portal', error)
		}
	}

	useEffect(() => {
		const fetchInvoice = async () => {
			if (activeTab !== 'general') return
			try {
				setLoading(true)

				const jwt = localStorage.getItem('jwtToken')
				const userJwt = jwtDecode(jwt)
				setUser(userJwt)

				const clientEmail = userJwt?.email

				const invoiceData = await axios.get(
					`${baseURL}/api/stripe/checkInvoice`,
					{
						params: { customerEmail: clientEmail },
					}
				)

				if (invoiceData.data) {
					setInvoiceData(invoiceData.data)
				}

				setLoading(false)
			} catch (error) {
				console.log('error', error)
				setLoading(false)
			}
		}

		fetchInvoice()
	}, [activeTab])

	const renderTabContent = () => {
		switch (activeTab) {
			case 'general':
				return (
					<GeneralSettings
						invoiceData={invoiceData}
						handleManageBilling={handleManageBilling}
						isDarkMode={isDarkMode}
						loading={loading}
					/>
				)
			case 'devices':
				return <DevicesSettings isDarkMode={isDarkMode} activeTab={activeTab} />
			default:
				return null
		}
	}

	const getTabStyle = tabName => {
		const isActive = activeTab === tabName
		const baseClasses =
			'cursor-pointer group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md transition ease-in-out duration-150'

		if (isDarkMode) {
			return `${baseClasses} ${
				isActive
					? 'text-white bg-gray-700'
					: 'text-gray-300 hover:text-white hover:bg-gray-600'
			} focus:outline-none focus:bg-gray-600`
		} else {
			return `${baseClasses} ${
				isActive
					? 'text-gray-900 bg-gray-200'
					: 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
			} focus:outline-none focus:bg-gray-200`
		}
	}

	return (
		<>
			<div style={{ backgroundColor: '#1c2127' }}>
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

				<div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-700 bg-0D1117 px-4 shadow-sm sm:px-6 lg:px-8">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-white"
						onClick={toggleSidebar}
					>
						<span className="sr-only">Toggle sidebar</span>
						<Bars3Icon className="h-5 w-5" aria-hidden="true" />
					</button>

					<Logo isLoadboard={true} />

					<div className="flex items-center ml-auto">
						{isDarkMode ? (
							<SunIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						) : (
							<MoonIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						)}
					</div>
				</div>

				<main className={`flex ${isDarkMode ? '' : 'bg-gray-100'}`}>
					{/* Tab navigation - now full height */}
					{/* <div
						className={`ml-4 w-64 min-h-screen   ${
							isDarkMode ? 'border-gray-700' : 'border-gray-200'
						}`}
					>
						<nav className="mt-5 px-2">
							<a
								className={getTabStyle('general')}
								onClick={() => setActiveTab('general')}
							>
								<svg
									className={`mr-4 h-6 w-6 ${
										isDarkMode ? 'text-gray-400' : 'text-gray-500'
									} group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
									/>
								</svg>
								General
							</a>

							<a
								className={getTabStyle('devices')}
								onClick={() => setActiveTab('devices')}
							>
								<svg
									className={`mr-4 h-6 w-6 ${
										isDarkMode ? 'text-gray-400' : 'text-gray-500'
									} group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150`}
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
									/>
								</svg>
								Devices
							</a>
						</nav>
					</div> */}

					{/* Tab content - takes up the rest of the space */}
					<div className={`flex-1 p-6 `}>{renderTabContent()}</div>
				</main>
			</div>

			<LoggedOutModal />
		</>
	)
}

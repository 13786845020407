import React, { useState } from 'react'
import dayjs from 'dayjs'
import axios from 'axios'

const DeviceCard = ({ device, isCurrent, isDarkMode }) => {
	const [loading, setLoading] = useState(false)

	const lastCheckIn = dayjs(device.lastCheckIn).fromNow()

	const handleTerminateSession = async () => {
		try {
			setLoading(true)

			await axios.post('api/user/terminate-session', {
				deviceId: device?._id,
			})

			setLoading(false)
		} catch (error) {
			console.log('error', error)
			setLoading(false)
		}
	}

	const textColorClass = isDarkMode ? 'text-gray-300' : 'text-gray-700'
	const headingColorClass = isDarkMode ? 'text-white' : 'text-gray-900'

	return (
		<div
			className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} border ${
				isDarkMode ? 'border-gray-700' : 'border-gray-200'
			} rounded-lg p-4 mb-4`}
		>
			<div className="flex justify-between items-center mb-2">
				<span className={`font-semibold ${headingColorClass}`}>
					{device.deviceType || 'UNKNOWN'}
				</span>
				<span
					className={`px-2 py-1 text-xs rounded-full ${
						isCurrent ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'
					}`}
				>
					{isCurrent ? 'Online' : lastCheckIn}
				</span>
			</div>
			<div className={`text-sm mb-1 ${textColorClass}`}>
				{device.city || 'Unknown'}, {device.region || 'Unknown'}{' '}
				{device.country || 'Unknown'}
			</div>
			<div className={`text-sm mb-2 ${textColorClass}`}>{device.os}</div>
			<div
				className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}
			>
				{device.deviceId}
			</div>
			{!isCurrent && (
				<button
					className="mt-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
					onClick={handleTerminateSession}
				>
					Terminate Session
				</button>
			)}
		</div>
	)
}

export default DeviceCard
